















































































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { WecomVideoServices } from '@/services/wecom-video-service'; //新的接口 换这里
import { CONDITION_AND_ALL } from '../constants';
import moment from 'moment';
import { DatePickerOptions } from 'element-ui/types/date-picker';
import { isEmpty } from 'lodash';
@Component({})
export default class VideoList extends Vue {
  [x: string]: any;
  public form: any = {
    pageSize: 10,
    pageNo: 1,
    sourceIds: [],
    description: '',
    targetVideoOption: 2,
    topFlag: 2,
    videoPublishOption: 2,
    updateTime: []
  };
  public publishForm: any = {
    effectTime: moment().startOf('days'),
  }
  public suncodeForm: any = {
    videoId: '',
  }
  public dialogFormVisible: Boolean = false;
  public dialogSuncodeFormVisible: Boolean = false;
  private tableData: any[] = [];
  public conditionOptions: any[] = CONDITION_AND_ALL;
  public tagOptions: any[] = [];
  public sourceList: any[] = [];
  public pickOptions: DatePickerOptions = {
    disabledDate(time) {
      return time.getTime() < moment().startOf('days').valueOf();
    },
  };
  public visible: Boolean = false;
  public loading: Boolean = true;
  public errorMsg: String = '';
  @Inject(WecomVideoServices)
  private wecomVideoServices!: WecomVideoServices;
  public async created(): Promise<void> {
    await this.getSourceList();
    await this.getList();
  }
  public createSuncode({ id: videoId,description }) {
    let scene: string = '';
    if (videoId) {
      scene = `videoId=V000${videoId}&from=suncode`
    }
    this.wecomVideoServices.createSuncode({
      videoId,
      scene,
      page: 'pages/auth/auth',
    }).then((res) => {
      const { suncode } = res;
      const fileName = `${description}.jpg`
      const link = document.createElement('a');
      
      link.target = '_blank';
      link.style.display = 'none';
      link.href = suncode;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
  }
  public create() {
    this.$router.push('/wecomVideo/add')
  }
  public search() {
    this.form.pageNo = 1;
    this.getList();
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.form.pageSize = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.pageNo = current;
    this.getList();
  }
  //获取数据源
  public async getList() {
    this.loading = true;
    try {
      const {
        pageSize,
        pageNo,
        sourceIds,
        targetVideoOption,
        videoPublishOption,
        updateTime,
        topFlag,
        description
      } = this.form;
      const params: Dict<any> = { pageSize, pageNo, sourceIds, description, topFlag, targetVideoOption, videoPublishOption };
      if (!isEmpty(updateTime)) {
        params.updateTimeFrom = moment(updateTime[0]).startOf('days').valueOf();
        params.updateTimeTo = moment(updateTime[1]).startOf('days').valueOf();
      }
      const res = await this.wecomVideoServices.getVideoList(params);
      this.formData(res)
      this.form.total = res.total;
    } catch (e) {

    }
    this.loading = false;

  }
  public async formData(res: any) {
    let data = res.records;
    this.tableData = data.map((item) => {
      return {
        ...item,
        topTime: item.topTime ? moment(item.topTime).format('YYYY-MM-DD') : ''
      }
    });
  }
  public swichChange(row) {
    console.log(row)
    this.wecomVideoServices.updateVideoTopFlag({
      id: row.id,
      topFlag: row.topFlag !== 1 ? 1 : 0,
      videoId: row.videoId
    }).then((res) => {
      console.log(res);
      this.form.pageNo = 1;
      if (res) {
        this.$message.success('操作成功');
      }
      this.getList();
    })
  }
  public async getSourceList(): Promise<void> {
    const res = await this.wecomVideoServices.getAuthSourceOptions();
    this.sourceList = res;
  }

  public async unshelve(row: any): Promise<void> {
    let param = {
      id: row.id
    };
    try {
      await this.$confirm('您是否确认下架该视频?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.wecomVideoServices.offSelves(param);
      this.getList();
      this.$message({
        message: '已下架该视频',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消操作'
      });
    }
  }
  public showPublish(row: any) {
    this.dialogFormVisible = true;
    this.publishForm.effectTime = moment().startOf('days').valueOf();
    this.publishForm.id = row.id;
  }

  public publish() {
    this.publishForm.effectTime = moment(this.publishForm.effectTime).startOf('days').valueOf()
    this.wecomVideoServices.videoPublish({
      ...this.publishForm
    }).then((res) => {
      console.log(res);
      if (res) {
        this.$message.success('操作成功');
        this.getList();
        this.dialogFormVisible = false;
      }
    });
  }

  public handleEdit(row: any) {
    this.$router.push({
      name: 'videoEdit',
      params: {
        videoId: row.id
      }
    })
  }

  public goDetail(row: any) {
    this.$router.push({
      name: 'videoDetail',
      params: {
        videoId: row.id
      }
    })
  }

  private async handDelete(row: any): Promise<void> {
    let param = {
      id: row.id
    };
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.wecomVideoServices.videoDelete(param);
      this.getList();
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  private async cancelPublish(row: any): Promise<void> {
    let param = {
      id: row.id
    };
    try {
      await this.$confirm('确定要取消发布吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.wecomVideoServices.videoCancel(param);
      this.getList();
      this.$message({
        message: '取消发布成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消操作'
      });
    }
  }
  private mounted() {
    document.onkeydown = e => {
      let key = (window.event as any).keyCode;
      if (key == 13) {
        this.search();
      }
    };
  }
  // 导出
  public async exportClick(): Promise<void> {
    const loading = this.$loading({
      text: '导出文件中...',
    })
    const {
      pageSize,
      pageNo, description,
      sourceIds,
      targetVideoOption,
      videoPublishOption,
      updateTime,
      topFlag
    } = this.form;
    let target = '0';
    if (targetVideoOption) {
      target = targetVideoOption
    }
    let publish = '0';
    if (videoPublishOption) {
      publish = videoPublishOption;
    }
    let top = '0';
    if (topFlag) {
      top = topFlag;
    }
    const params: Dict<any> = { pageSize, topFlag: top, pageNo, description, sourceIds, targetVideoOption: target, videoPublishOption: publish };
    if (!isEmpty(updateTime)) {
      params.updateTimeFrom = moment(updateTime[0]).startOf('days').valueOf();
      params.updateTimeTo = moment(updateTime[1]).startOf('days').valueOf();
    }
    this.wecomVideoServices.videoExport(params, `视频列表_${moment().format('YYYY年MM月DD日_HH时mm分ss秒')}.xlsx`).finally(() => {
      loading.close();
    });
  }
}
